.search_result {
    max-width: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    max-height: 300px;
    overflow-y: scroll;
}

.search_result::-webkit-scrollbar {
    display: none;
}

.search_suggestion_line {
    padding: 10px 20px;
    cursor: pointer;
    display: block;
}

.search_suggestion_line:hover {
    background-color: #efefef;
}

.search_suggestion_line.active {
    background-color: #efefef;
}
