input[type="checkbox"] {
    display: none;
  }
  
input[type="checkbox"] + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    font-size: 14px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #fff;
    border:solid 1px #c6c6c6;
    padding: 8px 10px;
    margin: 0 5px 5px 0;
    display: inline-block;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* input[type="checkbox"]:hover + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: #c6c6c6; 
} */
  
input[type="checkbox"]:checked + label{
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: #42a5f5; 
    color: white; 
    border-color:#42a5f5;
}
  