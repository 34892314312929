/* Hide Google logo */
/* a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
} */

.Map {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100%;
  width: 100%;
}

.imageSize {
  max-width: 400px;
  max-height: 200px;
}

.blueMarker {
  color: #5899f4;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.redMarker {
  color: #ff4d4d;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.orangeMarker {
  color: #ff9933;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.greenMarker {
  color: #2aa04c;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.lightpurpleMarker {
  color: #707ec0;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.brownMarker {
  color: #993300;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.purpleMarker {
  color: #9933ff;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.cyanMarker {
  color: #15bfbf;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.grayMarker {
  color: #6b8492;
  font-size: 14px;
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
